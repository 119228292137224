.signup__container_all {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: center;
  min-height: 100vh; 
  background: linear-gradient(45deg, #c4dce5, #5877b0);
  padding-top: 50px;
  height: calc(100vh - 50px);
  background-image: url(../images/5.2.3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.signup__background {
  margin: 50px;
  background: linear-gradient(45deg, #c4dce5, #5877b0);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.signup__header {
  text-align: center;
  width: 100%;
  height: 50px;
  background: linear-gradient(45deg, #41606b, #d9e0ee);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.signup__header-link,
.signup__title {
  color: #fff;
  text-decoration: none;
}

.signup__card {
  display: flex;
  flex-direction: column;
  background: linear-gradient(45deg, #c4dce5, #5d93f5);
  padding: 50px;
  border-radius: 8px;
}

.signup__form {
  width: 100%;
  padding: 20px;
}

.signup__label,
.signup__input {
  width: calc(50% - 10px);
  display: inline-block;
}

.signup__label {
  padding: 2px;
  margin-bottom: 15px;
}

.signup__input {
  padding: 5px;
  margin-bottom: 15px;
  border: #7c99ef 3px solid;
}

.signup__submit {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.signup__login-button {
  background-color: #003cff;
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.footer__container {
  width: 100%; 
  height: 150px; 
  background: linear-gradient(145deg, #012b3e, #4a8dad);
  color: white;
  padding: 20px 0;
  text-align: center;
}


.footer h3 {
  margin: 0;
  padding-bottom: 10px;
  font-size: 1.8em;
  font-weight: bold;
  text-transform: uppercase;
  background: -webkit-linear-gradient(left, #f9d423 0%, #ff4e50 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer p {
  margin: 5px 0 15px;
  font-size: 1em;
  line-height: 1.5;
  max-width: 600px;
}

.footer-tech-stack {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15px;
}

.footer-tech-stack span {
  background: #ffffff20;
  padding: 5px 15px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.footer-tech-stack span:hover {
  background: #ffffff40;
}

.footer-company {
  font-size: 0.9em;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .signup__background {
    padding: 10px;
    margin: 5px;
  }
  .signup__container {
    padding: 10px;
    margin: 5px;
  }
  .signup__card {
    padding: 5px;
  }
  .signup__label,
  .signup__input {
    width: 80%;
    display: block;
  }

  .signup__label {
    text-align: left;
    padding: 0;
  }
  .signup__submit {
    width: 80%;
  }
}

@media (max-width: 375px) {
  .signup__card {
    padding: 10px;
  }

  .signup__title,
  .signup__heading {
    font-size: 1.2rem;
  }
}
