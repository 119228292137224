.PaymentForm {
  max-width: 500px;
  margin: auto;
  background: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.PaymentForm__Form {
  display: flex;
  flex-direction: column;
}

.PaymentForm__Fieldset {
  margin-bottom: 20px;
  border: none;
}

.PaymentForm__Row {
  margin-bottom: 15px;
}

.PaymentForm__CardElement {
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.PaymentForm__TotalPrice {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.TotalPrice__Text {
  margin: 0;
}

.PaymentForm__ErrorMsg {
  color: #d32f2f;
  margin-bottom: 15px;
}

.PaymentForm__Button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.PaymentForm__Button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.PaymentForm__Status {
  text-align: center;
}

.Status__Heading {
  color: #333;
}
