/* Sidebar Styles */
.sidebar {
  background-color: #2c3e50;
  color: #ecf0f1;
  width: 300px; 
  height: 100vh;
  padding-top: 20px;
  box-sizing: border-box; 
  max-width: 100%;
  overflow-y: auto;
}

.sidebar a {
  display: block; 
  color: #e7f6f4;
  padding: 12px 20px; 
  text-decoration: none; 
  font-size: 16px; 
  border-radius: 20px; 
  background: linear-gradient(145deg, #012b3e, #56687a); 
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
  transition: transform 0.2s ease-in-out, background 0.3s; 
  text-align: left;
  margin: 15px;
  animation: linkFadeIn 0.5s ease-out forwards, colorShift 5s infinite linear;
  animation-delay: calc(0.1s * var(--i));
  position: relative; 
  overflow: hidden;
}

.sidebar a:hover {
  transform: scale(1.05); 
  background: linear-gradient(145deg, #4c774c, #3b4e68);
}

.sidebar a:hover::after {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  transform: skewX(-20deg) translateX(-100%);
  animation: shimmer 1s infinite;
}

.sidebar a.active {
  background: linear-gradient(145deg, #76b852, #8DC26F);
  color: #ffffff;
}

#logout-link {
  background: linear-gradient(145deg, #e74c3c, #c0392b); 
  margin-top: 20px; 
  font-weight: bold; 
  text-align: center; 
  cursor: pointer; 
  border-radius: 20px;
  transition: transform 0.2s, background 0.3s;
  width: 75%;
}

#logout-link:hover {
  transform: translateY(-3px); 
  background: linear-gradient(145deg, #ff6b6b, #e74c3c);
}

/* Subtle animation for links */
@keyframes linkFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Color shift animation for a dynamic background */

/* Shimmer effect on hover */
@keyframes shimmer {
  to {
    transform: skewX(-20deg) translateX(100%);
  }
}


.sidebar a:focus {
  outline: none;
  transform: scale(1.05);
  background: linear-gradient(145deg, #4c774c, #3b4e68);
  box-shadow: 0 0 0 3px rgba(76, 119, 76, 0.5);
}

/* Responsive adjustments for iPhone */
@media (max-width: 428px) {
  .sidebar {
    width: 100%; /* Sidebar takes full width on smaller screens */
    height: auto; /* Adjust height for better scrolling */
    overflow-y: auto; /* Maintain scrollability */
  }

  /* You might want to adjust the size of sidebar items or text for smaller screens */
  .sidebar a {
    font-size: 14px; /* Slightly smaller font size */
    padding: 10px 15px; /* Adjust padding */
  }

  #logout-link {
    width: 90%; /* Adjust width for better fit */
    margin-left: 5%; /* Center the logout link */
    margin-right: 5%;
  }
}

/* Responsive adjustments for iPad */
@media (max-width: 1024px) {
  .sidebar {
    /* Optional: Adjustments for iPads or larger devices if needed */
    /* For example, you might want to keep the sidebar width but adjust other properties */
  }

  /* Adjust sidebar link styles if needed */
  .sidebar a {
    /* Adjustments for sidebar items on iPad */
  }

  /* Adjust logout link style if needed */
  #logout-link {
    /* Adjustments for the logout link on iPad */
  }
}