.category-container {
  padding: 20px;
  background: linear-gradient(45deg, #17353f, #313c53);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 2rem auto;
}

.category-heading {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.category-form {
  margin-top: 20px;
  background: linear-gradient(45deg, #41606b, #011741);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

.input-field, .select-field {
  background: linear-gradient(45deg, #c4dce5, #5d93f5);
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
  width: calc(100% - 30px);
}

.submit-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #45a049;
}

.category-list-title {
  color: rgb(255, 255, 255);
  font-size: 22px;
  font-weight: bold;
  padding-top: 30px;
  text-align: center;
}

.category-list {
  list-style-type: none;
  padding: 0;
}

.category-item {
  background: linear-gradient(45deg, #c4dce5, #5877b0);
  border: 1px solid #ddd;
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.category-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.edit-btn, .delete-btn {
  padding: 5px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
  transition: background-color 0.3s;
}

.edit-btn {
  background-color: #1e90ff;
  color: white;
}

.edit-btn:hover {
  background-color: #1c86ee;
}

.delete-btn {
  background-color: #ff4444;
  color: white;
}

.delete-btn:hover {
  background-color: #cc0000;
}
