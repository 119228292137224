.footer {
    /* background: linear-gradient(145deg, #012b3e, #4a8dad); */
    background: linear-gradient(to right, #41606b 5%, #d9e0ee 180%);
    color: rgb(228, 194, 3);
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .footer h3 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 1.8em;
    font-weight: bold;
    text-transform: uppercase;
    background: -webkit-linear-gradient(left, #f9d423 0%, #ff4e50 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .footer p {
    margin: 5px 0 15px;
    font-size: 1em;
    line-height: 1.5;
    max-width: 600px;
  }
  
  .footer-tech-stack {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .footer-tech-stack span {
    background: #ffffff20;
    padding: 5px 15px;
    border-radius: 20px;
    transition: background-color 0.3s ease;
  }
  
  .footer-tech-stack span:hover {
    background: #ffffff40;
  }
  
  .footer-company {
    font-size: 0.9em;
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      padding: 0 20px;
    }
  }
  