/* OrderConfirmation.css located in the '../css/' directory */
.orderConfirmation {
  display: flex;
  height: 600px;
  width: 700px;
  text-align: center;
  margin-top: 50px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
  background-color: #df0c0ca5;
}

.orderConfirmation h1 {
  color: #150101;
  font-family: "Arial", sans-serif;
  margin-bottom: 20px;
}

.orderConfirmation p {
  font-family: "Helvetica", sans-serif;
  color: #0f0000;
  font-size: 24px;
  line-height: 1.6;
}

.orderConfirmation p.success {
  color: #28a745; /* Green color for success messages */
  font-weight: bold;
}

.orderConfirmation p.error {
  color: #011f07; /* Red color for error messages */
  font-weight: bold;
}

.mainOrderConfirmation {
  background: linear-gradient(
    148deg,
    rgba(38, 184, 209, 1) 0%,
    rgba(32, 105, 124, 1) 27%,
    rgba(24, 5, 16, 0.39117653897496496) 61%
  );
}

@media (max-width: 768px) {
  .orderConfirmation {
    margin: 20px;
    padding: 15px;
  }
}
/* OrderConfirmation.css */
.mainOrderConfirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure it covers the full viewport height */
  padding: 20px; /* Add padding for smaller screens */
  background: linear-gradient(
    135deg,
    rgba(38, 184, 209, 1) 0%,
    rgba(32, 105, 124, 1) 50%,
    rgba(24, 5, 16, 0.7) 100%
  );
}

.orderConfirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px; /* Adjusted max-width for consistency */
  width: 100%; /* Ensure it's responsive */
  padding: 40px; /* Increased padding for a more spacious layout */
  border-radius: 15px; /* Softer border-radius for modern look */
  background-color: rgba(
    255,
    255,
    255,
    0.85
  ); /* Slightly transparent white for depth */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow for 3D effect */
  text-align: center;
}

.orderConfirmation h1 {
  margin-bottom: 30px; /* Increased spacing for visual hierarchy */
  color: #102a43; /* Dark blue for better readability and contrast */
  font-family: "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

.orderConfirmation p {
  color: #334e68; /* Darker gray for better readability */
  font-size: 18px; /* Adjusted font size for better legibility */
  line-height: 1.5; /* Improved line height for readability */
}

.orderConfirmation p.success {
  color: #047857; /* Adjusted green for a modern palette */
}

.orderConfirmation p.error {
  color: #b91c1c; /* Adjusted red for a modern palette */
}

.orderDetails {
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f4f8; /* Light background for the details area */
  border: 1px solid #d1d5db; /* Border to define the details area */
  border-radius: 10px; /* Rounded corners for the details box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.returnButton {
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #102a43; /* Dark blue to match the heading */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.returnButton:hover {
  background-color: #334e68; /* A lighter shade for hover effect */
}

@media (max-width: 768px) {
  .orderConfirmation {
    padding: 20px; /* Adjust padding for smaller screens */
    margin: 10px; /* Adjust margin for smaller screens */
  }

  .orderConfirmation p,
  .orderConfirmation h1 {
    font-size: 16px; /* Adjust font size for better readability on small devices */
  }
}
