@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.product-detail-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  font-family: 'Montserrat', sans-serif;
}

.product-detail-name {
  color: #2c3e50;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center;
}

.product-detail-price {
  font-size: 1.8rem;
  color: #8e44ad;
  margin-bottom: 25px;
  text-align: center;
}

.product-detail-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  margin-bottom: 30px;
  justify-items: center;
}

.product-detail-image {
  width: 100px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.product-detail-image:hover {
  transform: scale(1.1);
}

.product-detail-description {
  color: #34495e;
  font-size: .8rem;
  line-height: 1.8;
  margin-bottom: 25px;
  border-left: 4px solid #8e44ad;
  padding-left: 15px;
}

.product-detail-quantity-adjuster {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.product-detail-quantity-decrease, .product-detail-quantity-increase {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  margin: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.product-detail-quantity-decrease:hover, .product-detail-quantity-increase:hover {
  background-color: #2980b9;
}

.product-detail-quantity-decrease:disabled, .product-detail-quantity-increase:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.product-detail-quantity-display {
  font-size: 1rem;
  padding: 5px 20px;
  margin: 0 15px;
  border-radius: 4px;
  background-color: #ecf0f1;
  border: 2px solid #bdc3c7;
}

.product-detail-total-price {
  font-size: 1.8rem;
  font-weight: 600;
  color: #16a085;
  margin-bottom: 25px;
  text-align: center;
}

.product-detail-add-to-cart-button {
  background-image: linear-gradient(to right, #f1c40f, #e67e22);
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: block;
  width: 80%;
  margin: 0 auto;
}

.product-detail-add-to-cart-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
