#barchart_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 50px;
}

.chart1,
.chart2,
.chart3 {
  min-height: 300px;
}

/* General responsive adjustment for smaller screens */
@media (max-width: 768px) {
  #barchart_container {
    grid-template-columns: 1fr;
    max-width: 500px;
  }
}

/* Specific adjustment for iPhone screen sizes */
@media (max-width: 428px) {
  #barchart_container {
    grid-template-columns: 1fr;
    padding: 20px; /* Adjust padding for smaller screens */
  }
}

/* Responsive adjustments for iPad screen sizes */
@media (max-width: 1024px) {
  #barchart_container {
    grid-template-columns: 1fr; /* Stack charts vertically on iPads */
    padding: 30px; /* Adjust padding for medium-sized screens */
  }

  .chart1, .chart2, .chart3 {
    /* Optional: Adjust chart sizes or other properties specifically for iPads */
  }
}
