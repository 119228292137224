.custom-slide-picture-container {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
}

.custom-slide-picture-container:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
}

.custom-slide-show {
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-image-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.custom-fade-in-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.custom-fade-in-image:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

.custom-slide-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid white;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
  outline: none;
}

.custom-slide-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.1);
}

.custom-slide-button.prev {
  left: 10px;
  border-radius: 0 10px 10px 0;
}

.custom-slide-button.next {
  right: 10px;
  border-radius: 10px 0 0 10px;
}
