.best-deal__trio {
    padding: 20px 0;
    background-color: #f7f7f7;
    text-align: center;
  }
  
  .products-heading__trio {
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .products-list__trio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .product-item__trio {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    width: 200px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .product-image__trio {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
  }
  
  .product-details__trio {
    padding: 10px;
    text-align: center;
  }
  
  .product-name__trio,
  .product-price__trio,
  .product-brand__trio {
    margin: 5px 0;
  }
  
  .add-to-cart-button__trio {
    background-color: #41606b;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart-button__trio:hover {
    background-color: #324b50;
  }
  
  @media (max-width: 768px) {
    .products-list__trio {
      gap: 15px;
    }
  
    .product-item__trio {
      width: 45%;
    }
  }
  
  @media (max-width: 480px) {
    .product-item__trio {
      width: 100%;
    }
  }
  