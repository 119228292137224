.fancy-best-products-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(145deg, #f9f9f9, #ececec);
  }
  
  .fancy-product-card {
    width: 300px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .fancy-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
  
  .fancy-product-image {
    height: 200px;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
  }
  
  .fancy-product-details {
    padding: 15px;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
  }
  
  .fancy-product-name {
    margin-top: 0;
    color: #333;
    font-weight: bold;
  }
  
  .fancy-price {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fancy-original-price {
    text-decoration: line-through;
    color: #999;
    font-size: 14px;
  }
  
  .fancy-discount-price {
    color: #d32f2f;
    font-size: 16px;
  }
  