.add-slide-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
  }
  
  .add-slide-title {
    text-align: center;
    color: #333;
  }
  
  .add-slide-form {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adds space between each input */
  }
  
  .add-slide-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px; /* Larger font size for better readability */
  }
  
  .add-slide-input:focus {
    border-color: #007bff;
    outline: none; /* Removes the default focus outline and replaces it with a border color change */
  }
  
  .add-slide-submit-btn {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .add-slide-submit-btn:hover {
    background-color: #0056b3; /* Darker shade on hover for visual feedback */
  }
  
  .add-slide-message {
    margin-top: 15px;
    text-align: center;
    color: #28a745; /* Success message in a green color */
  }
  