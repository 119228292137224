.users-content {
  padding: 25px;
  background: linear-gradient(45deg, #17353f, #313c53);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.users-content h2 {
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.user-form, .table-container {
  margin-bottom: 15px;
}

.user-form .form-label, .user-table th {
  font-weight: 600;
  margin-bottom: 8px;
  color: rgb(255, 255, 255);
}

.user-form .text-input, .user-table td {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background:linear-gradient(145deg, #4a7181, #b3c3e0);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  color: #000;
}

.user-form .action-btn, .user-table .edit-btn, .user-table .delete-btn {
  margin-top: 10px;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-form .action-btn {
  background-color: #007bff;
  color: #fff;
}

.user-table .edit-btn {
  background-color: #f1c40f;
  color: #fff;
}

.user-table .delete-btn {
  background-color: #e74c3c;
  color: #fff;
}

.user-form .action-btn:hover, .user-table .edit-btn:hover, .user-table .delete-btn:hover {
  background-color: darken(background-color, 10%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  transition: all 0.3s ease;
}

.user-table th, .user-table td {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.user-table th {
  background: linear-gradient(45deg, #41606b, #011741);
}

.user-table tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
  transform: scale(1.02);
  transition: transform 0.3s ease, background-color 0.2s ease;
}

.user-table tbody tr:last-child td {
  border-bottom: none;
}

.table-container {
  overflow-x: auto;
}
/* Responsive styles for iPhones */
@media (max-width: 428px) {
  .users-content {
    padding: 15px;
  }

  .users-content h2 {
    font-size: 20px;
  }

  .user-form .text-input, .user-table td {
    padding: 8px;
  }

  .user-form .action-btn, .user-table .edit-btn, .user-table .delete-btn {
    padding: 10px 20px;
  }

  .user-table th, .user-table td {
    padding: 12px; 
  }
}

/* Responsive styles for iPads */
@media (max-width: 1024px) {
  .users-content {
    padding: 20px;
  }

  .users-content h2 {
    font-size: 21px;
  }

  
  .user-form .text-input, .user-table td {
    padding: 10px; 
  }

  .user-form .action-btn, .user-table .edit-btn, .user-table .delete-btn {
    padding: 11px 22px;
  }

  .user-table th, .user-table td {
    padding: 14px;
  }
}
