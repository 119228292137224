.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  background: linear-gradient(45deg, #304c57, #000d24);
  background-size: cover;
  height: auto;
  min-height: 100vh;
  width: auto;
 
}
.admin_dashboard_info {
  color: #fff;
  font-size: 18px;
  margin-bottom: 2px;
}


.main-content .action-btn,
.main-content .edit-btn,
.main-content .delete-btn {
  padding: 8px;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .main-content {
    padding: 15px; 
  }

  .admin_dashboard_info {
    font-size: 16px;
  }

  .main-content .text-input,
  .main-content .form-label {
    width: 100%;
  }

  .main-content .action-btn,
  .main-content .edit-btn,
  .main-content .delete-btn {
    padding: 6px 10px;
    margin: 2px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .main-content {
    flex-direction: column;
  }

  .bar_graph_component,
  .table-container {
    width: 100%;
  }

  .main-content .action-btn,
  .main-content .edit-btn,
  .main-content .delete-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
