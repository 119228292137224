.shippingFormContainer {
  display: flex;

  background: linear-gradient(
    148deg,
    rgba(38, 184, 209, 1) 0%,
    rgba(28, 61, 76, 1) 26%,
    rgba(212, 30, 58, 0.39117653897496496) 41%,
    rgba(23, 18, 7, 1) 58%,
    rgba(80, 152, 17, 0.39117653897496496) 81%
  );
}
.form-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  background: linear-gradient(45deg, #41606b, #011741);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
}

.form-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out;
  font-size: 16px;
  background: linear-gradient(124deg, #c4dce5, #5d93f5);
}

.form-input:focus {
  border-color: #66afe9;
  outline: none;
}

.submit-btn-shipping {
  width: 100%;
  background-color: #6c63ff;
  background-image: linear-gradient(to right, #8e2de2, #4a00e0);
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  transition: background 0.3s ease-in-out;
}

.submit-btn-shipping:hover {
  background-image: linear-gradient(to right, #9d50bb, #6e48aa);
}

.form-input::placeholder {
  color: #aaa;
  font-size: 16px;
}

.shiiip {
  background: linear-gradient(
    148deg,
    rgba(38, 184, 209, 1) 0%,
    rgba(28, 61, 76, 1) 26%,
    rgba(212, 30, 58, 0.39117653897496496) 41%,
    rgba(23, 18, 7, 1) 58%,
    rgba(80, 152, 17, 0.39117653897496496) 81%
  );
}

.paymenth2 {
  font-family: "Courier New", Courier, monospace;
}

.ShiiipTitle{
  font-family: "Courier New", Courier, monospace;


}
