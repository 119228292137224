#user-dashboard {
  font-family: "Arial", sans-serif;
  color: rgb(255, 255, 255);
  padding: 20px;
  background: #f0f4f8;
  min-height: 100vh;
}

.best__deal, .our__products {
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;
  margin: 1em 0;
  color: #000;
}

.best__deal::first-letter, .our__products::first-letter {
  font-size: 1.2em;
  color: #ff4757;
}

.link, #logout-link-user {
  display: inline-block;
  background-color: #ff4757;
  color: white;
  padding: 10px 10px;
  margin: 15px;
  border-radius: 80px;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.link:hover, #logout-link-user:hover,
#view-cart-link:hover, #shopping-info-link:hover,
#order-history-link:hover, #add_cart_btn:hover,
#add_fav_btn:hover, #view_fav_btn:hover, #view_trc_btn:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#unique-products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.unique-product-item {
  width: 250px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.unique-product-item:hover {
  transform: translateY(-5px);
}

.product-name {
  color: white;
  padding: 10px;
  margin: 0;
  background: linear-gradient(to right, #41606b 0%, #d9e0ee 100%);
}

.product--price, .product--brand {
  padding: 5px 10px;
  color: #666;
  background: #f9f9f9;
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.add-to-cart-btn, .add-to-favorites-btn {
  width: 50%;
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  background: linear-gradient(to right, #41606b 12%, #d9e0ee 82%);
  color: white;
}

.add-to-cart-btn:hover {
  background-color: #45a049;
}

.add-to-favorites-btn:hover {
  background-color: #0b7dda;
}

.product-details-link {
  display: block;
  text-decoration: none;
  color: rgb(0, 75, 162);
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.product-details-link:hover {
  background: linear-gradient(to right, #41606b 12%, #d9e0ee 82%);
}

@media (max-width: 768px) {
  .unique-product-item {
    width: 100%;
  }
}
