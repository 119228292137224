.brand-area__trio {
  text-align: center;
  width: 100%;
  height: 50px;
  background: linear-gradient(45deg, #41606b, #d9e0ee);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.brand-link__trio {
  text-decoration: none;
  color: #fff;
  flex-grow: 1;
  text-align: left;
}

.brand-title__trio {
  margin: 0;
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
}

.brand-highlight__trio {
  color: #e2c08d;
}

.login_signup_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup__login-button,
.login__signup-button {
  margin: 0 10px;
  padding: 5px 15px;
  border: none;
  background-color: #41606b;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.signup__login-button:hover,
.login__signup-button:hover {
  background-color: #324b50;
}

.cart__items_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart__items_container button {
  background-color: #e2c08d;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.cart__items_container button:hover {
  background-color: #c8a678;
}

.cart__items_container p {
  margin-right: 50px;
}

.add-to-cart-button__trio {
  background-color: #e2c08d;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.add-to-cart-button__trio:hover {
  background-color: #c8a678;
}

@media only screen and (max-width: 414px) {
  .brand-area__trio {
    flex-direction: column;
    height: auto;
    padding: 10px 20px;
  }

  .brand-link__trio {
    margin-bottom: 10px;
    text-align: center;
  }

  .login_signup_container,
  .cart__items_container {
    justify-content: center;
  }

  .product-item__trio {
    width: 100%;
  }

  .cart__items_container p {
    margin: 10px 0 0 0;
  }
}

/* iPad Responsivity */
@media only screen and (max-width: 768px) {
  .brand-area__trio {
    padding: 10px 15px;
  }

  .products-list__trio {
    gap: 15px;
  }

  .product-item__trio {
    width: 45%;
  }

  .cart__items_container p {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .product-item__trio {
    width: 30%;
  }
}
