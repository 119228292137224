#orders-container {
  padding: 20px;
  background: linear-gradient(45deg, #41606b, #011741);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  width: 90%;
  max-width: 100%;
}

#orders-title {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 20px;
}

.order-card-style {
  background: linear-gradient(45deg, #41606b, #d9e0ee);
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
}

.order-header-style {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.order-date-style,
.order-total-style,
.order-id-style,
.payment-status-style {
  font-weight: bold;
}

.order-total-style,
.order-id-style {
  color: #007bff;
}

.user-email-style {
  display: block;
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #333;
}

.product-details-style {
  display: flex;
  align-items: center;
}

.item-details-style {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-image-style {
  width: 80px; 
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

.item-info-style p {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
}

.order-footer-style {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.payment-status-style {
  color: #4CAF50;
}

.edit-btn-style {
  background: linear-gradient(45deg, #f1c40f, #f39c12);
  color: rgb(3, 0, 0);
  font-weight: 900;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.edit-btn-style:hover {
  background-color: #a6eda9;
}

.delete-btn-style {
  background: linear-gradient(210deg, #e46376, #ff2f00);
  color: rgb(3, 0, 0);
  font-weight: 900;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.delete-btn-style:hover {
  background-color: #a6eda9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .order-card-style {
    grid-template-columns: 1fr;
  }

  .order-header-style, .order-footer-style {
    grid-column: 1 / -1;
  }
}
