#product-form-container {
    background: linear-gradient(45deg, #17353f, #313c53);
    padding: 5px;
    border-radius: 25px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

#product-form-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

#form-heading, #products-heading {
    color: rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); 
}

#product-form {
    background: linear-gradient(45deg, #41606b, #011741);
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

#form-row, #form-section, #attributes-section {
    margin-bottom: 20px;
}

.form-flex-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1px;
}

.form-col {
    flex: 1;
    min-width: 350px;
    margin-right: 20px; 
}

.form-col:last-child {
    margin-right: 0;
}

.form-label_product {
    display: block;
    margin-bottom: 8px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    flex-basis: 20%;
}

.form-input, .attribute-input, .form-select, .attribute-select {
    width: calc(80% - 12px);
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d3d4d5;
    margin-bottom: 10px;
    transition: border-color 0.3s ease; 
    background: linear-gradient(45deg, #c4dce5, #5d93f5);
}

.form-input:focus, .attribute-input:focus, .form-select:focus, .attribute-select:focus {
    border-color: #a2a2a2;
    outline: none;
}

.attribute-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.add-attribute-btn, .remove-attribute-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 12px;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.2s; 
}

.add-attribute-btn {
    background: linear-gradient(145deg, #4caf50, #67c86f);
    color: white;
}

.add-attribute-btn:hover {
    transform: scale(1.05); 
}

.remove-attribute-btn {
    background: linear-gradient(145deg, #f44336, #f6655a); 
    color: white;
}

.remove-attribute-btn:hover {
    transform: scale(1.05);
}

#submit-btn {
    background: linear-gradient(to right, #2196f3, #2599e6); 
    color: white;
    transition: background-color 0.3s;
    border-radius: 80px;
    width: 100%;
    font-size: 20px;
}

#submit-btn:hover {
    background: linear-gradient(to right, #1976d2, #208eeb);
}

.products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 24px;
    margin-top: 24px;
}

.product-item {
    border: 2px solid #2893d0; 
    border-radius: 20px; 
    padding: 10px;
    color: #ffffff; 
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.product-item:hover {
    transform: translateY(-5px); 
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

.product-info {
    font-weight: 600;
    font-size: 22px; 
    margin-bottom: 10px;
}

.product-price {
    color: #917e12;
    font-weight: 600; 
    font-size: 20px; 
    margin-bottom: 15px; 
}

.product-quantity, .product-category, .product-attributes {
    font-size: 16px; 
    margin-bottom: 12px; 
    font-style: italic;
}

.product-image {
    width: 80%;
    border-radius: 18px;
    margin-bottom: 20px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.product-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px; 
}

#edt, #dlt {
    padding: 12px 24px; 
    border: none;
    border-radius: 30px; 
    font-weight: 500;
    letter-spacing: 0.5px; 
    transition: background-color 0.3s ease, transform 0.2s ease; 
}

#edt {
    background-color: #3f51b5; 
    color: #ffffff; 
}

#dlt {
    background-color: #f44336; 
    color: #ffffff; 
}

#edt:hover, #dlt:hover {
    background-color: darken(#3f51b5, 10%); 
    transform: scale(1.05);
}

/* New CSS styles start here */

.product-item input[type="text"] {
    width: calc(100% - 42px);
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d3d4d5;
    margin-bottom: 10px;
    transition: border-color 0.3s ease;
    background: linear-gradient(45deg, #c4dce5, #5d93f5);
}

.product-item input[type="text"]:focus {
    border-color: #a2a2a2;
    outline: none;
}

.product-item button {
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, transform 0.2s;
}

.product-item button:hover {
    transform: scale(1.05);
}

