.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  background:linear-gradient(45deg, #c4dce5, #5877b0);
  height: 100vh;
  background-image: url(../images/5.2.3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login__background {
  background:linear-gradient(45deg, #c4dce5, #5877b0);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.login__header {
  text-align: center;
  width: 100%;
  height: 50px;
  background: linear-gradient(45deg, #41606b, #d9e0ee);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.login__header-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.login__header-link:hover {
  color: #a85bd1;
}

.login__title {
  font-size: 24px;
  color: #fff;
}


.login__container {
  max-width: 400px;
}

.login__card {
  background: linear-gradient(45deg, #c4dce5, #5d93f5);
  padding: 50px;
  border-radius: 8px;
}

.login__heading {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}


.login__form {
  display: flex;
  flex-direction: column;
}

.login__label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.login__input {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.login__submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login__submit:hover {
  background-color: #0056b3;
}

.login__signup-link {
  margin-top: 10px;
  text-align: center;
}

.login__signup-text {
  font-size: 14px;
  color: #333;
}

.login__signup-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login__signup-button:hover {
  background-color: #218838;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .login__container {
    max-width: 80%;
    padding: 10px;
  }
  .login__card{
    padding: 45px;
  
  }
}

@media only screen and (max-width: 375px) {
  .login {
    height: 100vh;
  }

  .login__title,
  .login__heading {
    font-size: 1.25rem; 
  }

  .login__input,
  .login__submit,
  .login__signup-button {
    font-size: 0.875rem;
    padding: 12px; 
  }
  
  .login__label {
    margin-bottom: 8px;
  }

  .login__signup-link {
    margin-top: 15px;
  }
}

