.tracking-info-heading {
  text-align: center;
  color: #4a4e69;
  font-size: 24px;
  font-weight: 600;
}

.tracking-info-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 15px;
  background: linear-gradient(145deg, #e6e9f0, #eef1f5);
}

.tracking-info-thead {
  background-color: #6c5ce7;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.tracking-info-header {
  padding: 15px;
  font-weight: 500;
}

.tracking-info-row {
  transition: background-color 0.3s ease;
}

.tracking-info-row:nth-child(odd) {
  background-color: #f8f9fa;
}

.tracking-info-row:hover {
  background-color: #dfe6e9;
}

.tracking-info-cell {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

.tracking-info-cell:nth-child(4) {
  font-weight: 600;
  border-radius: 5px;
  background: #00b894;
  color: #ffffff;
  text-align: center;
  transition: background-color 0.3s;
}

.tracking-info-cell:nth-child(4):hover {
  background-color: #0984e3;
}

@media screen and (max-width: 768px) {
  .tracking-info-table,
  .tracking-info-cell,
  .tracking-info-header {
    display: flex;
    flex-direction: column;
  }

  .tracking-info-cell,
  .tracking-info-header {
    width: 100%;
    box-sizing: border-box;
    font-size: 20px;
    margin: 1px;
  }
}

@media screen and (min-width: 1024px) {
  .tracking-info-container {
    padding: 30px;
    margin: 50px auto;
  }

  .tracking-info-heading {
    font-size: 28px;
    margin-bottom: 40px;
  }

  .tracking-info-header,
  .tracking-info-cell {
    padding: 20px;
  }
}

@media screen and (min-width: 1366px) {
  .tracking-info-container {
    padding: 40px;
    max-width: 1200px;
  }

  .tracking-info-heading {
    font-size: 32px;
    margin-bottom: 50px;
  }

  .tracking-info-header,
  .tracking-info-cell {
    padding: 25px;
  }
}
