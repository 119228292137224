#order-history-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 90%;
  max-width: 100%;
    margin: 2rem auto;
  }
  
  #order-history-title {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  #order-list {
    display: flex;
    flex-direction: column;
  }
  
  #order-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 15px;
  }
  
  #order-info, #user-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  #product-details-container {
    margin-bottom: 15px;
  }
  
  #product-details {
    display: flex;
    margin-bottom: 15px;
  }
  
  #product-image {
    width: 100px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  #order-status {
    font-weight: bold;
    text-align: right;
  }
  
  #no-orders-message {
    text-align: center;
  }
  