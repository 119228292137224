.cart-container {
    padding: 20px;
    max-width: 800px;
    margin: 40px auto;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  
  .cart-heading {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    color: #333;
    font-weight: bold;
  }
  
  .cart-item {
    background: linear-gradient(145deg, #e6e9f0, #eef1f5);
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .cart-item:hover {
    transform: translateY(-5px);
  }
  
  .cart-item-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    border: 2px solid #e1e1e1;
  }
  
  .cart-item-title, .cart-item-price, .cart-item-quantity {
    margin: 0 15px;
    color: #333;
  }
  
  .stock-warning, .stock-adjustment-warning {
    color: #d9534f;
  }
  
  .quantity-btn, .remove-btn, .proceed-btn {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .quantity-btn:hover, .remove-btn:hover, .proceed-btn:not(:disabled):hover {
    background-color: #333;
    color: #fff;
  }
  
  .increase-quantity {
    background-color: #5cb85c;
    color: white;
  }
  
  .decrease-quantity {
    background-color: #f0ad4e;
    color: white;
  }
  
  .remove-btn {
    background-color: #d9534f;
    color: white;
  }
  
  .proceed-btn {
    background-color: #0275d8;
    color: white;
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .proceed-btn:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
  
  .empty-cart-message, .total-price {
    text-align: center;
    color: #888;
    font-size: 18px;
  }
  
  .total-price {
    font-weight: bold;
    margin-top: 15px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .cart-item {
      flex-direction: column;
      align-items: center;
    }
  
    .cart-item-image {
      margin-bottom: 15px;
    }
  
    .quantity-btn, .remove-btn {
      margin: 5px;
    }
  
    .proceed-btn {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .cart-container {
      padding: 15px;
    }
  
    .cart-heading {
      font-size: 20px;
    }
  
    .cart-item-title, .cart-item-price, .cart-item-quantity {
      margin: 5px 0;
      text-align: center;
    }
  
    .proceed-btn {
      padding: 10px 0;
    }
  }
  /* Larger Screen */
@media (min-width: 1024px) {
  .cart-container {
    padding: 30px; 
    max-width: 1200px;
  }

  .cart-heading {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .cart-item {
    padding: 20px; 
    margin-bottom: 30px; 
  }

  .cart-item-image {
    width: 100px;
    height: 100px; 
    margin-right: 30px;
  }

  .cart-item-title, .cart-item-price, .cart-item-quantity {
    margin: 0 20px;
    font-size: 20px;
  }

  .quantity-btn, .remove-btn, .proceed-btn {
    padding: 12px 20px; 
    font-size: 20px;
  }

  .proceed-btn {
    margin-top: 30px;
    font-size: 22px; 
  }

  .empty-cart-message, .total-price {
    font-size: 22px; 
  }
}
