#inventory-form-container {
    background: linear-gradient(45deg, #17353f, #313c53);
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
  max-width: 100%;
  margin: 2rem auto;
}

#inventory-form-heading {
    color: rgba(255,255,255);
    text-align: center;
    margin-bottom: 25px;
}

.form-field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}



