.site-header {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  background: linear-gradient(to right, #506a78, #90b7fb);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
}

.site-header__top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.brand-area__title {
  color: #ffffff;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  justify-content: flex-start;
}

.logo-area {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
  background-size: cover;
  background-image: url("../images/Logo3.jpg");
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.search-area {
  flex-grow: 2;
  position: relative;
  margin: 0 1.5rem;
}

.search-area__input {
  width: 100%;
  border: none;
  padding: 0.75rem;
  border-radius: 30px;
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
}

.search-area__button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #506a78;
  font-size: 1.2rem;
}

.user-actions {
  display: flex;
  align-items: center;
}

.user-actions a {
  background: linear-gradient(135deg, #6e8ea5 30%, #7095ae 100%);
  color: #ffffff;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 2rem;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.user-actions a:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #6e8ea5, #fbc97e);
}

.dropdown {
  position: relative;
}

.dropbtn {
  background: linear-gradient(to right, #30444f, #7d94ba); /*Depatments menu*/
  color: rgb(255, 255, 255);
  padding: 0.8rem 1.6rem;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0.2rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background: linear-gradient(135deg, #6e8ea5, #fbc97e);
  color: #333;
}

.products-dropdown {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-top: 3rem;
  position: relative;
}
.products-display_search {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 1rem;
  position: absolute;
  top: 32%;
  left: 1%;
  right: 10%;
}

.product-item_header {
  background: linear-gradient(45deg, #c4dce5, #90b5f9);
  border: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.product-item_header:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

.category-item_header {
  background: linear-gradient(45deg, #c4dce5, #1a52ba);
  border: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
.category-item_header:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
  background: linear-gradient(135deg, #6e8ea5, #fbc97e);
}

.product-item {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  background: linear-gradient(135deg, #6e8ea5, #9fbfd4);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.product-item:hover {
  background-color: #e9e9e9;
}

.category__item {
  padding: 0.5rem 1rem;
  margin: 5px 0;
  cursor: pointer;
  background-color: #a3e1f5;
  border: 1px solid #cfd8dc;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  color: #007bff;
  text-decoration: none;
}

.category__item:hover,
.category__item:focus {
  background-color: #d0d9dd;
  transform: scale(1.05);
  color: #01579b;
}

.site-navigation {
  border-top: 2px solid #e0e0e0;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.site-navigation_ {
  border-top: 3px solid #e0e0e0;
  margin-top: 5px;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .site-header__top-section {
    flex-direction: column;
    align-items: flex-start;
  }
  .logo-area {
    width: 80px;
    height: 85px;
    margin-left: 40%;
  }

  .search-area {
    width: 80%;
    margin-top: 0.5rem;
  }
  .search-area__button {
    margin-right: -80px;
    background: linear-gradient(to right, #9fbfd4, #6e8ea5);
    color: rgb(248, 227, 157);
    padding: 0.3em;
    border-radius: 20px;
  }
  .search-area__button:hover {
    background: linear-gradient(to right, #6e8ea5, #9fbfd4);
    color: rgb(255, 255, 255);
  }
  .products-display_search {
    display:flex;
    flex-direction: row;
    margin-top: 300px;
    padding: 20px;
    z-index: 1;
  }

  .user-actions {
    justify-content: center;
    margin-top: 0.8rem;
    padding-left: 2rem;
    padding-bottom: 0.5em;
    margin-left: 10px;
  }
  .dropdown {
    margin-left: 1px;
  }
  .products-dropdown {
    padding: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .user-actions a,
  .dropdown .dropbtn {
    width: 100%;
    margin: 0.2rem 0;
    justify-content: center;
  }
}
