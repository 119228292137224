#shipping-container {
  padding: 20px;
  background: linear-gradient(45deg, #41606b, #011741);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 100%;
  margin: 2rem auto;
}

#shipping-title {
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  margin-bottom: 20px;
}

#shipping-list {
  display: flex;
  flex-direction: column;
}

.shipping-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  background: linear-gradient(45deg, #41606b, #d9e0ee);
  border: 2px solid #eee;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

#shipping-item-header {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(45deg, #c4dce5, #5877b0);
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

#shipping-item-header h3 {
  margin: 0;
  font-size: 1.2em;
  color: #007bff;
}

#shipping-id {
  font-weight: bold;
  color: #333;
}

#shipping-item-body {
  display: contents;
}

#shipping-item-body p {
  margin: 10px; 
  padding: 10px;
  border-bottom: 1px solid #eee;
}

#shipping-item-body p:last-child {
  border-bottom: none; 
}

#shipping-no-info {
  text-align: center;
  color: #999;
  margin-top: 20px;
}
