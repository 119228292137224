.add-best-product {
    background: #f0f4f7;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-width: 600px;
    margin: 30px auto;
  }
  
  .form-title {
    color: #333;
    text-align: center;
  }
  
  .product-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label_best_product_best_product {
    display: block;
    margin-bottom: 5px;
    color: #000000;
  }
  
  .form-input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .form-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-image {
    background-color: #4CAF50;
    color: white;
  }
  
  .submit {
    background-color: #008CBA; 
    color: white;
    align-self: center;
  }
  
  .form-input:focus {
    border-color: #007bff;
  }
  