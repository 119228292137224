.favorites-display-container {
  padding: 30px;
  max-width: 900px;
  margin: 20px auto;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.favorites-heading {
  text-align: center;
  margin-bottom: 30px;
  color: #2c3e50;
  font-size: 26px;
  font-weight: bold;
}

.favorites-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 15px;
}

.favorite-item {
  background: #eceff1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.favorite-item:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.favorite-item-name, .favorite-item-brand {
  margin: 8px 0;
  color: #34495e;
}

.favorite-item-image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #b0bec5;
  
}

.favorite-remove-btn, .move-all-favorites-btn {
  display: block;
  width: calc(100% - 10px);
  padding: 10px;
  margin: 8px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.2s ease-in-out;
}

.favorite-remove-btn:hover, .move-all-favorites-btn:hover {
  transform: scale(1.05);
}

.favorite-remove-btn {
  background-color: #e74c3c;
  color: #ecf0f1;
}

.move-all-favorites-btn {
  background-color: #3498db;
  color: #ecf0f1;
}

.no-favorites-message {
  text-align: center;
  color: #7f8c8d;
  font-size: 18px;
}
/*screens wider than 1024px */
@media (min-width: 1024px) {
  .favorites-display-container {
    padding: 40px;
    max-width: 1100px;
  }

  .favorites-heading {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .favorites-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
    gap: 20px; 
  }

  .favorite-item {
    padding: 25px; 
  }

  .favorite-item-name, .favorite-item-brand {
    font-size: 20px; 
  }

  .favorite-item-image {
    width: 140px; 
    height: 140px; 
  }

  .favorite-remove-btn, .move-all-favorites-btn {
    padding: 12px; 
    font-size: 18px;
  }
}

@media (min-width: 1366px) {
  .favorites-display-container {
    padding: 50px;
    max-width: 1280px;
  }

  .favorites-heading {
    font-size: 34px;
    margin-bottom: 50px;
  }

  .favorites-list {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 25px;
  }

  .favorite-item {
    padding: 30px;
  }

  .favorite-item-name, .favorite-item-brand {
    font-size: 22px;
  }

  .favorite-item-image {
    width: 160px;
    height: 160px;
  }

  .favorite-remove-btn, .move-all-favorites-btn {
    padding: 14px;
    font-size: 20px;
  }
}
