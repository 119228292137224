.unique-gallery-container {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
  margin: 30px;
  padding: 10px;
  margin-top: 60px;
}

.unique-gallery-container:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
}

.unique-gallery-slideshow {
  width: 100%;
  height: 420px;
  display: block;
  background-image: none;
  background-repeat: repeat;

  
}

.unique-image-wrapper {
  width: 100%;
  height: 100%;
}

.unique-fade-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.unique-fade-image:hover {
  opacity: 0.9;
  transform: scale(1.05);
}

.unique-gallery-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0);
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 100;
  outline: none;
}

.unique-gallery-nav:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.1);
}

.unique-gallery-nav.prev {
  left: 10px;
  border-radius: 0 10px 10px 0;
}

.unique-gallery-nav.next {
  right: 10px;
  border-radius: 10px 0 0 10px;
}

.unique-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  transition: opacity 0.3s ease;
}
