#trackingFormContainer {
  padding: 20px;
  background: linear-gradient(45deg, #17353f, #313c53);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
}

.formFieldRow {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  margin-right: 20px;
}
#trackingFormTitle{
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 15px;
  color: rgb(255, 255, 255);
}

.formField label {
  font-weight: 600;
  margin-bottom: 8px;
}

.formField input, .formField select {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: linear-gradient(45deg, #c4dce5, #5d93f5);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
}

#submitButton {
  margin-top: 10px;
  padding: 12px 25px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-self: flex-start;
}

#submitButton:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

#submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}


#submittedTrackingInfoContainer {
  margin-top: 30px;
  background: linear-gradient(45deg, #c4dce5, #0a214b);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

#trackingInfoTable {
  width: 100%;
  border-collapse: collapse;
  transition: all 0.3s ease;
}

#trackingInfoTable th, #trackingInfoTable td {
  text-align: left;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

#trackingInfoTable th {
  background: linear-gradient(45deg, #41606b, #011741);
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 12px;
  text-align: left;
  border: none;
  transition: transform 0.3s ease;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#trackingInfoTable tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
  transform: scale(1.02);
  transition: transform 0.3s ease, background-color 0.2s ease;
}

#trackingInfoTable tbody tr:last-child td {
  border-bottom: none;
}

.table-responsive {
  overflow-x: auto;
}

#submissionMessage {
  text-align: center;
  color: #28a745;
  margin-bottom: 20px;
  font-size: 18px;
}

